<template>
  <div style="margin-top: 100px">
    <v-row>
      <v-col md="6" offset-md="3">
        <v-row>
          <v-col cols="12">
            <h1>Pagos en efectivo</h1>
          </v-col>
        </v-row>
        <v-tabs v-model="tab" color="deep-purple-accent-4" align-tabs="center">
          <v-tab :value="1">Cursos</v-tab>
          <v-tab :value="2">Certificaciones</v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item>
            <v-row>
              <v-col
                cols="12"
                md="4"
                v-for="order in courses"
                :key="order.orderId"
              >
                <v-card elevation="2">
                  <v-card-title>
                    <v-row>
                      <v-col cols="4">
                        Orden
                      </v-col>
                      <v-col cols="8"
                        >Status:
                        <span v-if="!order.datePaid" class="error--text"
                          >Pendiente</span
                        ><span v-else class="success--text">Pagada</span></v-col
                      >
                    </v-row>
                  </v-card-title>
                  <v-card-subtitle>{{ order.orderId }}</v-card-subtitle>
                  <v-card-text>${{ order.amount }}</v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="deep-purple lighten-2"
                      text
                      @click="OpenOxxoReference({ router: $router, order })"
                    >
                      Abrir
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item>
            <v-row>
              <v-col
                cols="12"
                md="4"
                v-for="order in certifications"
                :key="order.orderId"
              >
                <v-card elevation="2">
                  <v-card-title>
                    <v-row>
                      <v-col cols="4">
                        Orden
                      </v-col>
                      <v-col cols="8"
                        >Status:
                        <span v-if="!order.datePaid" class="error--text"
                          >Pendiente</span
                        ><span v-else class="success--text">Pagada</span></v-col
                      >
                    </v-row>
                  </v-card-title>
                  <v-card-subtitle>{{ order.orderId }}</v-card-subtitle>
                  <v-card-text>${{ order.amount }}</v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="deep-purple lighten-2"
                      text
                      @click="OpenOxxoReference({ router: $router, order })"
                    >
                      Abrir
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" absolute bottom color="success" outlined>
      Perfil actualizado
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">
          Ok
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { structOrder } from "../helpers/paymentOrderHelpers";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      profile: null,
      snackbar: false,
      show1: false,
      show2: false,
      show3: false,
      password: null,
      passwordConfirm: null,
      newPassword: null,
      actualizando: false,
      courses: null,
      certifications: null,
      tab: 0,
    };
  },

  mounted() {
    var headers = {};
    headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.$store.state.accessToken,
    };
    this.axios
      .get("/user/profile", { headers: headers })
      .then((response2) => {
        this.profile = response2.data.response;
        return response2.data.response;
      })
      .then((res) => {
        this.axios.get(`/course/payments/${res.uuid}`).then((r) => {
          this.courses = structOrder(r.data.courses);
          this.courses = this.courses.filter((x) => x);
          this.certifications = structOrder(r.data.certifications);
          this.certifications = this.certifications.filter((x)=> x);
        });
      }).catch(e => {
          if(e.response.status === 401)
            this.$store.dispatch('LogoutException')
        })
  },
  methods: {
    ...mapActions(["OpenOxxoReference"]),
  },
};
</script>
